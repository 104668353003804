<style lang="less">
@media (max-width: 1024px) {
	.el-menu-item.is-active,
	.el-submenu__title {
		border-bottom: none;
		padding: 0 10px;
	}
	.el-menu-item.is-active.last {
		background-color: #46a853;
		color: #fff !important;
	}
	.el-menu-item {
		border-bottom: none;
		padding: 0 10px;
	}
	.el-menu--horizontal .el-menu-item.is-active,
	.el-submenu__title {
		border-bottom: none !important;
	}
	.el-menu--horizontal .el-menu-item.is-active.last {
		background-color: #46a853;
		color: #fff !important;
	}
	.el-menu--horizontal .el-menu-item {
		border-bottom: none;
		font-weight: 700;
		font-size: 12px;
	}
	.el-menu--horizontal.el-menu .el-menu-item {
		font-size: 14px !important;
		height: 40px;
		line-height: 40px;
	}
	.el-menu.el-menu--horizontal,
	.el-submenu__title {
		border-bottom: none;
		&:hover {
			background: #ededed !important;
		}
	}
	.el-menu--horizontal .el-menu-item:not(.is-disabled):not(.last):focus,
	.el-menu--horizontal .el-menu-item:not(.is-disabled):not(.last):hover,
	.el-submenu__title a:focus,
	.el-submenu__title a:hover,
	.el-menu-item:not(.last) a:focus,
	.el-menu-item:not(.last) a:hover {
		color: #46a853 !important;
		background: #ededed !important;
	}
	.el-menu-item.last {
		background-color: #46a853;
		color: #fff !important;
	}
	.el-menu-item.last:hover {
		background-color: #424242 !important;
		color: #fff !important;
	}
	.el-submenu {
		.el-submenu__title {
			font-weight: bold;
			font-size: 14px !important;
		}
	}
	.el-menu--horizontal {
		width: 100% !important;
		height: 44px;
		line-height: 44px;
		text-align: center;
		background: #f2f2f2;
	}
	.el-menu-item:not(.last) {
		background: #f2f2f2 !important;
		width: 100% !important;
	}
	.el-submenu,
	.el-submenu__title {
		width: 100% !important;
		height: 44px !important;
		line-height: 44px !important;
		text-align: center;
		background: #f2f2f2 !important;
	}
	.navContain-app {
		margin: 0 auto;
		width: 100%;
		box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 6%);
		padding: 10px;
		background: #fff;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;
		.nav {
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			.iconfont {
				font-size: 40px;
				margin-right: 60px;
				&:hover {
					color: #46a853;
				}
			}
			.logo {
				width: 80px;
			}
			.title {
				margin-left: 90px;
			}
		}
		.navTitle {
			width: 100%;
			height: 308px;
			position: fixed;
			top: 79px;
			left: 0;
			z-index: 99;
			background: #f2f2f2;
			.el-menu {
				background: #f2f2f2;
			}
		}
	}
	.free-quote {
		width: 100%;
	}
}
</style>
<template>
	<div class="navContain-app">
		<div class="nav">
			<span class="iconfont icon-sanheng1" v-if="!isShow" @click="open"></span>
			<span class="iconfont icon-close" v-else @click="close"></span>
			<div class="logo">
				<img :src="imgURL + 'logo.png'" alt="" />
			</div>
			<div class="title">
				<el-menu
					class="el-menu-demo"
					mode="horizontal"
					@select="handleSelect"
					text-color="#333"
					active-text-color="#46a853"
				>
					<el-menu-item class="last" index="8">
						<x-router-link to="/get-a-free-quote">
							<div class="free-quote">Get Quick Quote</div>
						</x-router-link>
					</el-menu-item>
				</el-menu>
			</div>
		</div>
		<div class="navTitle" v-if="isShow">
			<el-menu
				class="el-menu-demo"
				mode="horizontal"
				@select="handleSelect"
				text-color="#333"
				menu-trigger="click"
				active-text-color="#46a853"
			>
				<el-menu-item index="1">
					<x-router-link to="/">Home</x-router-link>
				</el-menu-item>
				<el-submenu index="2">
					<template slot="title">
						<x-router-link to="/">Dropshipping Agent</x-router-link>
					</template>
					<el-menu-item index="2-1" @click="close">
						<x-router-link to="/3pl-fulfillment">
							3PL Fulfillment Agent
						</x-router-link>
					</el-menu-item>
					<el-menu-item index="2-2" @click="close">
						<x-router-link to="/china-sourcing-agent">
							Sourcing Agent
						</x-router-link>
					</el-menu-item>
					<el-menu-item index="2-3" @click="close">
						<x-router-link to="/1688-agent">1688 Agent</x-router-link>
					</el-menu-item>
					<el-menu-item index="2-4" @click="close">
						<x-router-link to="/taobao-agent-service">
							Taobao Agent
						</x-router-link>
					</el-menu-item>
					<el-menu-item index="2-5" @click="close">
						<x-router-link to="/amazon-supplier">Amazon Supplier</x-router-link>
					</el-menu-item>
					<el-menu-item index="2-6" @click="close">
						<x-router-link to="/aliexpress-agent-service">
							AliExpress Agent Service
						</x-router-link>
					</el-menu-item>
				</el-submenu>
				<el-submenu index="3">
					<template slot="title">
						<x-router-link to="/our-services">Our Services</x-router-link>
					</template>
					<el-menu-item index="3-1" @click="close">
						<x-router-link to="/china-sourcing-agent">
							Product Sourcing
						</x-router-link>
					</el-menu-item>
					<el-menu-item index="3-2" @click="close">
						<x-router-link to="/product-inspection-service">
							Product Inspection
						</x-router-link>
					</el-menu-item>
					<el-menu-item index="3-3" @click="close">
						<x-router-link to="/3pl-fulfillment">3PL Fulfillment</x-router-link>
					</el-menu-item>
					<el-menu-item index="3-4" @click="close">
						<x-router-link to="/product-branding">
							Product Branding
						</x-router-link>
					</el-menu-item>
					<el-menu-item index="3-5" @click="close">
						<x-router-link to="/private-label-dropshipping-service">
							Private label dropshipping
						</x-router-link>
					</el-menu-item>
					<el-menu-item index="3-6" @click="close">
						<x-router-link to="/1-v-1-customer-service">
							1v1 customer services
						</x-router-link>
					</el-menu-item>
					<el-menu-item index="3-7" @click="close">
						<x-router-link to="/after-sale-service">
							After-Sale Services
						</x-router-link>
					</el-menu-item>
					<el-menu-item index="3-8" @click="close">
						<x-router-link to="/free-product-photo">
							Product Photography
						</x-router-link>
					</el-menu-item>
				</el-submenu>
				<el-menu-item index="4" @click="close">
					<x-router-link to="/frequently-asked-questions">Faqs</x-router-link>
				</el-menu-item>
				<el-menu-item index="5" @click="close">
					<x-router-link to="/about-us">About us</x-router-link>
				</el-menu-item>
				<el-menu-item index="6" @click="close">
					<x-router-link to="/contact-us">Contact us</x-router-link>
				</el-menu-item>
				<el-menu-item index="7">
					<a href="https://customer.bestfulfill.com.cn/">Customer</a>
				</el-menu-item>
			</el-menu>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Headers',
	data() {
		return {
			imgURL: process.env.VUE_APP_IMG_URL,
			isShow: false,
		};
	},
	props: {
		from: {
			type: String,
		},
	},
	created() {},
	mounted() {},
	methods: {
		handleSelect(key, keyPath) {
			console.log(key, keyPath);
		},
		open() {
			this.isShow = true;
		},
		close() {
			this.isShow = false;
		},
	},
};
</script>
