<style lang="less" scoped>
.title {
	font-weight: bold;
	font-size: 36px;
	color: #000;
	margin-bottom: 20px;
}
.el-row {
	padding: 0 20%;
	margin: 0 auto;
	.el-col {
		.gutter {
			height: 280px;
			padding: 30px 0;
			background: #fff;
			margin-bottom: 20px;
			box-shadow: 0 8px 20px 2px rgba(0, 0, 0, 0.07);
			border-bottom: 10px solid #00b67a;
			border-radius: 5px;
			.fifth-contain {
				margin: 0 auto;
				width: 80%;
				.imgBg {
					width: 96%;
					border-radius: 10px;
					margin: 0 auto;
					display: flex;
					.avatar {
						width: 80px;
						height: 80px;
						background: #cccccc;
						border-radius: 50%;
						overflow: hidden;
						margin-right: 10px;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
					.name-score {
						flex: 1;
						text-align: left;
						h4 {
							font-weight: 600;
						}
						span {
							color: #2bc54d;
							font-weight: 600;
						}
						.star {
							width: 18px;
							height: 18px;
							display: inline-block;
							margin-left: 4px;
							margin-top: 4px;
							.el-icon-star-on {
								background: #00b67a;
								color: #fff;
								font-size: 18px;
							}
						}
					}
				}
				.content1 {
					font-size: 16px;
					margin: 10px 0;
					text-align: left;
				}
				.content2 {
					color: #7a7a7a;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					overflow-y: auto;
					text-overflow: ellipsis;
					text-align: left;
				}
			}
		}
	}
}
@media (max-width: 767px) {
	.gutter {
		height: auto !important;
		.fifth-contain {
			.imgBg {
				.avatar {
					width: 55px !important;
					height: 55px !important;
				}
			}
		}
	}
}
</style>
<template>
	<div>
		<el-row :gutter="20">
			<el-col
				:xs="24"
				:sm="24"
				:lg="6"
				v-for="(item, index) in evaluateList"
				:key="index"
			>
				<div class="gutter">
					<div class="fifth-contain">
						<div class="imgBg">
							<div class="avatar">
								<img :src="item.image" alt="" />
							</div>
							<div class="name-score">
								<h4>{{ item.name }}</h4>
								<span>5.0</span>
								<span class="star" v-for="item in 5" :key="item">
									<i class="el-icon-star-on"></i>
								</span>
							</div>
						</div>
						<div class="content1">{{ item.content1 }}</div>
						<div class="content2">
							{{ item.content2 }}
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>
<script>
export default {
	data() {
		return {
			evaluateList: [
				{
					image: process.env.VUE_APP_IMG_URL + 'home/Gail.png',
					name: 'Gail Freeman',
					content1: '',
					content2:
						'I spoke to one of the advisors Jacob zhao and he was extremely	helpful and understanding. He knew what I was looking for and	resolved my issue. Though i was panicking and was very upset with the bulk order issues but he made sure that I get the	right information. I will give 5/5 to him. Well done buddy😊',
				},
				{
					image: process.env.VUE_APP_IMG_URL + 'home/Jomari.png',
					name: 'Jomari Tamala',
					content1: 'I just love the great customer service',
					content2:
						"I just love the great customer service, Bestfulfill's is the	real thing for Dropshippers!",
				},
				{
					image: process.env.VUE_APP_IMG_URL + 'home/Earlean.png',
					name: 'Earlean Vosburgh',
					content1: "I'm having a great experience with…",
					content2:
						"I'm having a great experience with Bestfulfill. They have great customer service.",
				},
				{
					image: process.env.VUE_APP_IMG_URL + 'home/Mathis.png',
					name: 'Mathis Fernandez',
					content1: 'This is a very great site to work with!',
					content2: 'This is a very great site to work with!',
				},
				{
					image: process.env.VUE_APP_IMG_URL + 'home/Ngqc.png',
					name: 'Ngọc Mai Bùi',
					content1: '',
					content2:
						'Super customer service, fast delivery, so far everything is great. Simple and simple to use. Thank you',
				},
				{
					image: process.env.VUE_APP_IMG_URL + 'home/Nathan.png',
					name: 'Nathan Wang',
					content1: '',
					content2: 'Very polite, quick to respond, and patient.great job .',
				},
				{
					image: process.env.VUE_APP_IMG_URL + 'home/placeholder-image.png',
					name: 'Cosywa',
					content1: 'Good business experience.',
					content2:
						'Good business experience. Cost effective to launch a new	product. Many services are provided like storage facility. Thank you Jennie for your help to set up it all.',
				},
				{
					image: process.env.VUE_APP_IMG_URL + 'home/placeholder-image.png',
					name: 'Anaïs',
					content1: 'The best team I have worked with',
					content2:
						'The best team I have worked with! Ivan & Alice are very responsive, very attentive and give the best of themselves to	ensure that everything goes well. I can count on them and that is the most important!',
				},
			],
		};
	},
};
</script>
