import Vue from 'vue';
import ElementUI from 'element-ui';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(ElementUI);
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/iconfont/iconfont.css';

// 注册全局组件----头部
import Headerspc from './components/headerfooter/headersPc.vue';
Vue.component('Headerspc', Headerspc);
import Headersapp from './components/headerfooter/headersApp.vue';
Vue.component('Headersapp', Headersapp);
// 注册全局组件----底部
import Footers from './components/headerfooter/footers.vue';
// import de from "element-ui/src/locale/lang/de";
Vue.component('Footers', Footers);
// 注册全局组件----route支持跳转外链
import XRouterLink from './components/router/routerLink';
Vue.component('x-router-link', XRouterLink);

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
