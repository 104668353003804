import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
	},
	{
		path: '/our-services',
		name: 'our-services',
		component: () => import('../views/ourServices.vue'),
	},
	{
		path: '/frequently-asked-questions',
		name: 'frequently-asked-questions',
		component: () => import('../views/askedQuestions.vue'),
	},
	{
		path: '/about-us',
		name: 'about-us',
		component: () => import('../views/aboutUs.vue'),
	},
	{
		path: '/contact-us',
		name: 'contact-us',
		component: () => import('../views/contactUs.vue'),
	},
	{
		path: '/get-a-free-quote',
		name: 'get-a-free-quote',
		component: () => import('../views/getAfreeQuote.vue'),
	},
	// Dropshipping Agent
	{
		path: '/3pl-fulfillment',
		name: '3pl-fulfillment',
		component: () => import('../views/fulfillment.vue'),
	},
	{
		path: '/china-sourcing-agent',
		name: 'china-sourcing-agent',
		component: () => import('../views/chinaSourcingAgent.vue'),
	},
	{
		path: '/1688-agent',
		name: '1688-agent',
		component: () => import('../views/1688agent.vue'),
	},
	{
		path: '/taobao-agent-service',
		name: 'taobao-agent-service',
		component: () => import('../views/taobaoAgentService.vue'),
	},
	{
		path: '/amazon-supplier',
		name: 'amazon-supplier',
		component: () => import('../views/amazonSupplier.vue'),
	},
	{
		path: '/aliexpress-agent-service',
		name: 'aliexpress-agent-service',
		component: () => import('../views/aliexpressAgent.vue'),
	},
	// Our Services
	{
		path: '/product-inspection-service',
		name: 'product-inspection-service',
		component: () => import('../views/productInspection.vue'),
	},
	{
		path: '/product-branding',
		name: 'product-branding',
		component: () => import('../views/productBranding.vue'),
	},
	{
		path: '/private-label-dropshipping-service',
		name: 'private-label-dropshipping-service',
		component: () => import('../views/privateLabelDropshipping.vue'),
	},
	{
		path: '/1-v-1-customer-service',
		name: '1-v-1-customer-service',
		component: () => import('../views/customerService.vue'),
	},
	{
		path: '/after-sale-service',
		name: 'after-sale-service',
		component: () => import('../views/afterSaleService.vue'),
	},
	{
		path: '/free-product-photo',
		name: 'free-product-photo',
		component: () => import('../views/freeProductPhoto.vue'),
	},
	// quote success
	{
		path: '/thanks-your-quote',
		name: 'thanks-your-quote',
		component: () => import('../views/thanksQuote.vue'),
	},
	{
		path: '/our-team',
		name: 'our-team',
		component: () => import('../views/ourTeam.vue'),
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.path !== from.path) {
		// 路由跳转前，将页面滚动到顶部
		window.scrollTo(0, 0);
	}
	next();
});

export default router;
